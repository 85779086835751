import { useRouter } from "next/router";

import { HOMEPAGE } from "@/constants";

const useGetNextPage = () => {
  const { query } = useRouter();
  let unauthenticatedPath: string;

  if (typeof query.unauthenticatedPath !== "string") {
    unauthenticatedPath = decodeURIComponent(
      [query.unauthenticatedPath].join(""),
    );
  } else {
    unauthenticatedPath = decodeURIComponent(query.unauthenticatedPath);
  }

  if (!unauthenticatedPath) {
    return HOMEPAGE;
  }

  // http://regexr.com/7tj96
  // ✅ /collection/0x2fa06c6672ddcc066ab04631192738799231de4a
  // ✅ /item/starkEx/0x2fa06c6672ddcc066ab04631192738799231de4a
  // ✅ %2Fitem%2FstarkEx%2F0x73f8af973f6173c4b4e06048a2d723e975a903cf%2F1%2F <-- url encoded
  // ✅ /rewards?_gl=1*1vf1pr9*_ga*ODY5MDQyNjY5LjE2NzMzOTU5NTU.*_ga_7XM4Y7T8YC*MTcxMTAwMjYyMi4xNDEuMS4xNzExMDAzMjA1LjAuMC4w';
  // ❌ /collection/?redirect_to=disney.com
  // ❌ /item/1234
  // ❌ guest<script>alert('attacked')</script>
  // ❌ %3c%73%63%72%69%70%74%3e%77%69%6e%64%6f%77%2e%6f%6e%6c%6f%61%64%20%3d%20%66%75%6e%63%74%69%6f%6e%28%29%20%7b%76%61%72%20%6c%69%6e%6b%3d%64%6f%63%75%6d%65%6e%74%2e%67%65%74%45%6c%65%6d%65%6e%74%73%42%79%54%61%67%4e%61%6d%65%28%22%61%22%29%3b%6c%69%6e%6b%5b%30%5d%2e%68%72%65%66%3d%22%68%74%74%70%3a%2f%2f%61%74%74%61%63%6b%65%72%2d%73%69%74%65%2e%63%6f%6d%2f%22%3b%7d%3c%2f%73%63%72%69%70%74%3e'
  // ❌ javascript:alert(1)

  const regexpMatches = unauthenticatedPath.match(
    /\/((rewards|balance|games|external-wallets)|(collection|item)\/\w+\/0x[a-f0-9]{40}(\/\d+)?)\/?/i,
  );
  if (regexpMatches) {
    const utmCampaign = unauthenticatedPath.match(
      /utm_campaign=(?<utm_campaign>[\w_-]+)/,
    );

    const utmMedium = unauthenticatedPath.match(
      /utm_medium=(?<utm_medium>[\w_-]+)/,
    );

    const utmSource = unauthenticatedPath.match(
      /utm_source=(?<utm_source>[\w_-]+)/,
    );

    const glCrossDomain = unauthenticatedPath.match(/_gl=(.+)/);

    const uri = new URL(regexpMatches[0], "http://example.com");
    if (utmCampaign) uri.searchParams.append("utm_campaign", utmCampaign[1]);
    if (utmMedium) uri.searchParams.append("utm_medium", utmMedium[1]);
    if (utmSource) uri.searchParams.append("utm_source", utmSource[1]);
    if (glCrossDomain) uri.searchParams.append("_gl", glCrossDomain[1]);
    return uri.pathname + uri.search;
  }

  return HOMEPAGE;
};

export { useGetNextPage };
