import { appConfigFn } from "@/constants/appConfig";

export const SMALL_APP_HEADER_BAR_HEIGHT = "80px";
export const SIDEBAR_WIDTH = "320px";
export const HOMEPAGE = "/balance";

export const appConfig = appConfigFn();
// @NOTE: use this when we have no valid image url to pass to Biome Image
// components, as this will have them display default imagery
export const BAD_IMAGE_URL = "https://something";
export const PINATA_GATEWAY_URL = "https://gateway.pinata.cloud";
