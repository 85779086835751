import { useFlags as useLDFlags } from "launchdarkly-react-client-sdk";

export type Flags = {
  onboarding: boolean;
  activityList: boolean;
  languageSwitcher: boolean;
  zkEvmActions: boolean;
  internalOnly: boolean;
  walletConnectEnabled: boolean;
  layerswapEnabled: boolean;
};

export function useFlags() {
  return useLDFlags<Flags>();
}
