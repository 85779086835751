import type { User } from "@braze/web-sdk";
import { createContext, useContext, useEffect } from "react";

import { appConfig } from "@/constants";
import { usePassportProvider } from "@/context";
import type { SubscriptionStatus } from "@/types";

import { usePreferences } from "./PreferencesProvider";

export const BrazeContext = createContext<{
  unsubscribe: () => void;
  optIn: () => void;
}>({
  unsubscribe: () => {},
  optIn: () => {},
});

const useBraze = () => {
  const ctx = useContext(BrazeContext);
  if (!ctx) {
    throw new Error("useBraze must be used within a BrazeProvider");
  }

  return ctx;
};

// https://www.braze.com/docs/user_guide/message_building_by_channel/email/managing_user_subscriptions/
const BrazeProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  let user: User | undefined;
  const { userInfo } = usePassportProvider();
  const { data, update } = usePreferences();

  useEffect(() => {
    if (userInfo) {
      import("@/utils/braze/exports").then((braze) => {
        braze?.initialize(appConfig.BRAZE_API_KEY, {
          baseUrl: appConfig.BRAZE_API_ENDPOINT,
          enableLogging: appConfig.NODE_ENV === "development",
        });
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        braze?.changeUser(userInfo?.email!);
        user = braze?.getUser();
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        user?.setEmail(userInfo.email!);
        braze?.openSession();
      });
    }
  }, [userInfo, user]);

  const updatePreference = (marketing_consent: SubscriptionStatus) => {
    update({ ...data, marketing_consent, onboard: false });
    user?.setPushNotificationSubscriptionType(marketing_consent);
    user?.setEmailNotificationSubscriptionType(marketing_consent);
  };

  return (
    <BrazeContext.Provider
      value={{
        unsubscribe: () => updatePreference("unsubscribed"),
        optIn: () => updatePreference("opted_in"),
      }}
    >
      {children}
    </BrazeContext.Provider>
  );
};

export { BrazeProvider, useBraze };
