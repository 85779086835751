import { PINATA_GATEWAY_URL } from "@/constants";

export function toSimpleAddressFormat(address: string): string {
  return `${address.slice(0, 6)}...${address.slice(-4)}`.toLowerCase();
}

export const isMatchingAddress = (addressA = "", addressB = "") =>
  addressA.toLowerCase() === addressB.toLowerCase();

export const safeTestId = (testId: string): string =>
  `${testId}`.replace(/[^a-zA-Z0-9-]/g, "-");

export const getMediaUrl = (url: string | undefined) => {
  if (url && url.startsWith("ipfs://")) {
    const cid = url.split("ipfs://")[1];
    return `${PINATA_GATEWAY_URL}/ipfs/${cid}`;
  }
  return url; // If it's not an IPFS URL, return as is
};
