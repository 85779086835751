import dynamic from "next/dynamic";
import type { ReactNode } from "react";

import { appConfig } from "@/constants";

const MoonPayConfig = dynamic(
  () => import("@moonpay/moonpay-react").then((mod) => mod.MoonPayProvider),
  { ssr: false },
);

export function MoonPayProvider({ children }: { children: ReactNode }) {
  return (
    <MoonPayConfig apiKey={appConfig.MOONPAY_API_KEY}>{children}</MoonPayConfig>
  );
}
