import type { checkout } from "@imtbl/sdk";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useFlags } from "launchdarkly-react-client-sdk";

import { appConfig } from "@/constants";
import { usePassportProvider } from "@/context";
import { useAccessToken } from "@/hooks/useAccessToken";
import {
  type ChainAddress,
  type CoinBalance,
  type Collection,
  type CollectionsResponse,
  type Item,
  type ItemData,
  type ItemDetails,
  type ItemOption,
  type Network,
  type Page,
  type TokenID,
  WalletTypes,
} from "@/types";
import { authorizedGet } from "@/utils/request";

import { getMediaUrl } from "@/utils/util";
import { itemMetadataTransformer } from "./useQuery/itemMetadataTransformer";

const iconSource = appConfig.TOKEN_ICONS_URL;
const COLLECTIONS_PAGE_SIZE = 30;
const ITEMS_PAGE_SIZE = 30;

// TODO @pano @ji: Refactor FiatPricingProvider so it can be reused across zkEVM and starkEx
//      For now, we are mantaining a list of tokens to fetch prices for in addition to the `supportedCurrencies`
const zkEVMCurrencies = ["gog", "usdt"];

export function useCoinBalancesZkEVMQuery(
  walletAddress: string | undefined,
  checkoutClient: checkout.Checkout | undefined,
  checkoutNetwork: checkout.NetworkInfo | undefined,
): {
  data: CoinBalance[] | undefined;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  error: any;
  isLoading: boolean;
} {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  return useQuery<any, any, CoinBalance[], any>({
    queryKey: ["coin-balances-zkevm", walletAddress, checkoutNetwork?.chainId],
    enabled: !!walletAddress && !!checkoutClient && !!checkoutNetwork,
    refetchInterval: 1000 * 15, // 15 seconds
    queryFn: async () => {
      if (!checkoutClient || !walletAddress || !checkoutNetwork?.chainId) {
        return [];
      }
      const data = await checkoutClient?.getAllBalances({
        walletAddress: walletAddress,
        chainId: checkoutNetwork?.chainId,
      });
      return data?.balances.map(
        (coinBalance: checkout.GetBalanceResult) =>
          <CoinBalance>{
            network: "zkEvm",
            name: coinBalance.token.name,
            symbol: coinBalance.token.symbol,
            contractAddress: coinBalance.token.address,
            balance: coinBalance.balance.toString(),
            imageUrl:
              coinBalance.token.address &&
              coinBalance.token.address === "native"
                ? `${iconSource}/imx.svg`
                : `${iconSource}/${coinBalance.token.address?.toLowerCase()}.svg`,
            decimals: coinBalance.token.decimals,
            wallet: walletAddress,
            walletType: WalletTypes.PASSPORT,
          },
      );
    },
  });
}

export function useCoinBalancesQuery(network: Network): {
  data: CoinBalance[] | undefined;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  error: any;
  isLoading: boolean;
} {
  const getAccessToken = useAccessToken();

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  return useQuery<any, any, CoinBalance[], any>({
    queryKey: ["coin-balances-imtbl-x"],
    refetchInterval: 1000 * 15, // 15 seconds
    queryFn: async () => {
      const accessToken = await getAccessToken();
      if (!accessToken) return undefined;
      return authorizedGet(
        `/passport-profile/v1/networks/${network}/coins`,
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        accessToken!,
      );
    },
    select: (data) =>
      data.map(
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        (coinBalance: any) =>
          <CoinBalance>{
            network: coinBalance.network,
            name: coinBalance.name,
            symbol: coinBalance.symbol,
            contractAddress: coinBalance.contract_address,
            balance: coinBalance.balance,
            imageUrl: coinBalance.image_url,
            decimals: coinBalance.decimals,
            wallet: coinBalance.wallet,
            walletType: coinBalance.wallet_type,
          },
      ),
  });
}

async function getSupportedCurrencies() {
  const apiKey = appConfig.MOONPAY_API_KEY;
  const resp = await axios.get(
    `https://api.moonpay.com/v3/currencies?apiKey=${apiKey}`,
    {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "Content-Type": "application/json",
      },
    },
  );

  const response = resp.data
    .filter((currency: { type: string }) => currency.type === "crypto")
    .map((currency: { code: string }) =>
      currency.code.replace("_immutable", ""),
    );

  return [...response, ...zkEVMCurrencies];
}

export function useSupportedCurrenciesQuery(): {
  data: string[];
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  error: any;
  isLoading: boolean;
} {
  const { data, error, isLoading } = useQuery({
    queryKey: ["supported-currencies"],
    queryFn: getSupportedCurrencies,
  });

  return {
    data: data ?? [], // TODO: Data can be undefined!
    error,
    isLoading,
  };
}

function getCollectionParams(
  pageSize: number,
  filterAddress: string,
  cursor: string,
): URLSearchParams {
  const params = new URLSearchParams({ page_size: pageSize.toString() });
  // Empty strings are invalid
  if (filterAddress) params.append("address", filterAddress.toLowerCase());
  if (cursor) params.append("page_cursor", cursor);
  return params;
}

export async function getCollections(
  accessToken: string | undefined,
  passportWallet: string | undefined,
  initial: boolean,
  zkEVMCursor: string,
  starkExCursor: string,
  filterAddress: string,
  blacklistedAddresses: string[] | undefined,
) {
  const requests = [];
  if (zkEVMCursor || initial) {
    requests.push(
      authorizedGet(
        `/passport-profile/v1/networks/zkEvm/collections?${getCollectionParams(
          COLLECTIONS_PAGE_SIZE,
          filterAddress,
          zkEVMCursor,
        ).toString()}`,
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        accessToken!,
      ),
    );
  }
  // Asset transfer not supported for StarkEx
  if (!filterAddress && (starkExCursor || initial)) {
    requests.push(
      authorizedGet(
        `/passport-profile/v1/networks/starkEx/collections?${getCollectionParams(
          COLLECTIONS_PAGE_SIZE,
          filterAddress,
          starkExCursor,
        ).toString()}`,
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        accessToken!,
      ),
    );
  }
  return Promise.all(requests).then((results) => {
    // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
    let zkEvmCursor;
    // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
    let starkExCursor;
    const collections: Collection[] = [];
    // biome-ignore lint/complexity/noForEach: <explanation>
    results.forEach((result) => {
      collections.push(
        ...result.result
          .filter(
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            (collection: any) =>
              !(
                blacklistedAddresses?.includes(collection.contract_address) ||
                collection.name.toLowerCase().includes("airdrop")
              ),
          )
          .map(
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            (collection: any) =>
              <Collection>{
                id: collection.network + collection.contract_address,
                network: collection.network,
                name: collection.name,
                symbol: collection.symbol,
                address: collection.contract_address,
                image: getMediaUrl(collection.image),
                icon: collection.icon,
                balance: Number.parseInt(collection.balance),
                owners: collection.owners,
                hasImportableAssets: collection.owners
                  ? collection.owners.filter(
                      (owner: string) => owner !== passportWallet,
                    ).length > 0
                  : false,
                tokenType: collection.token_type,
              },
          ),
      );
      const network = result.result.length > 0 ? result.result[0].network : "";
      if (network === "zkEvm") {
        zkEvmCursor = result.page.next_cursor;
      }
      if (network === "starkEx") {
        starkExCursor = result.page.next_cursor;
      }
    });
    return {
      zkEvmCursor,
      starkExCursor,
      collections,
    } as CollectionsResponse;
  });
}

export function useCollectionsQuery(
  address: string | undefined,
  enabled: boolean,
): {
  data: CollectionsResponse | undefined;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  error: any;
  isLoading: boolean;
  refetch: () => Promise<any>;
} {
  const getAccessToken = useAccessToken();
  const { walletAddress } = usePassportProvider();
  const flags = useFlags();
  const blacklistedCollections = flags.collectionsDenyList?.split(",");

  const { data, error, isLoading, refetch } = useQuery<
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    any,
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    any,
    CollectionsResponse,
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    any
  >({
    queryKey: ["collectionsV2", address],
    queryFn: async () => {
      const accessToken = await getAccessToken();
      if (!accessToken) return undefined;
      return getCollections(
        accessToken,
        walletAddress,
        true,
        "",
        "",
        address ?? "",
        blacklistedCollections,
      );
    },
    enabled: enabled,
  });
  return {
    data,
    error,
    isLoading,
    refetch,
  };
}

export function useItemDetailsQuery(
  network: Network | undefined,
  collection: ChainAddress | undefined,
  tokenId: TokenID | undefined,
  owner: string,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
): { data: ItemDetails | undefined; error: any; isLoading: boolean } {
  const getAccessToken = useAccessToken();

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  return useQuery<any, any, ItemDetails, any>({
    queryKey: ["item-details", network, collection, tokenId],
    queryFn: async () => {
      const accessToken = await getAccessToken();
      if (!accessToken) return undefined;
      return authorizedGet(
        `/passport-profile/v1/networks/${network}/collections/${collection}/items/${tokenId}?owner=${owner}`,
        accessToken,
      );
    },
    enabled: !!network && !!collection && !!tokenId,
    select: (data) =>
      <ItemDetails>{
        network: data.network,
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        collection: collection!,
        collectionName: data.collection_name,
        tokenId: data.token_id,
        owner: data.owner,
        status: data.status,
        name: data.name,
        description: data.description,
        image: getMediaUrl(data.image),
        animationUrl: getMediaUrl(data.animation_url),
        metadata: itemMetadataTransformer(data),
        createdAt: data.created_at ? new Date(data.created_at) : undefined,
        updatedAt: data.updated_at ? new Date(data.updated_at) : undefined,
        balance: data.balance,
        tokenType: data.token_type,
      },
  });
}

function getItemsParams(
  filterAddress: string,
  cursor: string,
  sortBy: string,
  sortDirection: string,
): URLSearchParams {
  const params = new URLSearchParams({
    page_size: ITEMS_PAGE_SIZE.toString(),
    sort_by: sortBy,
    sort_direction: sortDirection,
  });
  // Empty strings are invalid
  if (filterAddress) params.append("address", filterAddress.toLowerCase());
  if (cursor) params.append("page_cursor", cursor);
  return params;
}

export function useItemsQuery({
  view,
  network,
  address,
  selectedOption,
  pageCursor,
  filterAddress,
}: {
  view: string;
  network: Network;
  address: string;
  selectedOption: ItemOption;
  pageCursor?: string;
  filterAddress?: string;
}): {
  isFetching: boolean;
  isLoading: boolean;
  data: ItemData | undefined;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  error: any;
} {
  const getAccessToken = useAccessToken();

  const fetchItems = async (
    selectedOption: ItemOption,
    network: "starkEx" | "zkEvm" | undefined,
    address: string,
  ) => {
    const accessToken = await getAccessToken();
    if (!accessToken || !address) return undefined;
    const params = getItemsParams(
      filterAddress ?? "",
      pageCursor ?? "",
      selectedOption.sortBy,
      selectedOption.sortDirection,
    );
    const url = `/passport-profile/v1/networks/${network}/collections/${address}/items?${params.toString()}`;

    return await authorizedGet(url, accessToken);
  };

  const { data, error, isLoading, isFetching } = useQuery({
    queryKey: ["items", selectedOption, network, address, view],
    queryFn: () => fetchItems(selectedOption, network, address),
    select: (data: { page: Page; result: Item[] }) =>
      <ItemData>{
        nextCursor: data.page.next_cursor,
        items: data.result.map(
          (item) =>
            <ItemDetails>{
              network: item.network,
              name: item.name,
              description: item.description,
              image: getMediaUrl(item.image),
              animationUrl: getMediaUrl(item.animation_url),
              tokenId: item.token_id,
              collection: address,
              owner: item.owner,
              balance: item.balance,
              tokenType: item.token_type,
            },
        ),
      },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
  };
}

export async function getItems(
  accessToken: string | undefined,
  network: Network,
  address: ChainAddress,
  selectedOption: ItemOption,
  pageCursor?: string,
  filterAddress?: string,
): Promise<ItemData> {
  const queryParams = getItemsParams(
    filterAddress ?? "",
    pageCursor ?? "",
    selectedOption.sortBy,
    selectedOption.sortDirection,
  );
  return await authorizedGet(
    `/passport-profile/v1/networks/${network}/collections/${address}/items?${queryParams.toString()}`,
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    accessToken!,
  ).then(
    (data: { page: Page; result: Item[] }) =>
      ({
        nextCursor: data?.page.next_cursor,
        items: data.result.map(
          (item) =>
            <ItemDetails>{
              network: item.network,
              name: item.name,
              description: item.description,
              image: getMediaUrl(item.image),
              animationUrl: getMediaUrl(item.animation_url),
              tokenId: item.token_id,
              collection: address,
              owner: item.owner,
              balance: item.balance,
            },
        ),
      }) as ItemData,
  );
}

export { itemMetadataTransformer };
