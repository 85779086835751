import { useCallback, useEffect, useState } from "react";

import { useImmutableProvider, usePassportProvider } from "@/context";

export function useAccessToken(): () => Promise<string | undefined> {
  const { passportClient } = useImmutableProvider();
  const { logout } = usePassportProvider();

  const getAccessToken = useCallback(async () => {
    try {
      return await passportClient?.getAccessToken();
    } catch (e) {
      // If this is reached there was an error silently refreshing inside the Passport SDK and a re-login is required
      logout();
    }
  }, [passportClient, logout]);
  return getAccessToken;
}
