import {
  type QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import type { AxiosError } from "axios";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useAccessToken } from "@/hooks";
import type { PartialProfilePrefences, ProfilePreferences } from "@/types";
import { authorizedGet, authorizedPatch } from "@/utils/request";
import { usePassportProvider } from "./PassportProvider";

const PREFERENCES_PATH = "/passport-profile/v1/preferences";

export const PreferencesContext = createContext<{
  data: ProfilePreferences;
  error?: Error | null;
  isLoading: boolean;
  isFetched: boolean;
  isPending: boolean;
  isError: boolean;
  update: (preference: PartialProfilePrefences) => void;
  errorStatus?: number;
  reset: () => void;
}>({
  data: {
    marketing_consent: "subscribed",
    onboard: false,
    reveal_address: false,
    hide_activation_badges: {
      balances: false,
      inventory: false,
      external_wallets: false,
    },
  },
  isLoading: false,
  isFetched: false,
  isPending: true,
  isError: false,
  update: () => {},
  reset: () => {},
});

const usePreferences = () => {
  const ctx = useContext(PreferencesContext);
  if (!ctx) {
    throw new Error("usePreferences must be used within a PreferencesProvider");
  }
  return ctx;
};

const PreferencesProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const getAccessToken = useAccessToken();
  const { passportState } = usePassportProvider();
  const [preferences, setPreferences] = useState<ProfilePreferences>({
    marketing_consent: "subscribed",
    onboard: false,
    reveal_address: false,
    hide_activation_badges: {
      balances: false,
      inventory: false,
      external_wallets: false,
    },
  });

  const queryKey: QueryKey = ["profile-preference"];
  const queryClient = useQueryClient();
  const queryFn = async () => {
    const accessToken = await getAccessToken();
    if (!accessToken) return undefined;
    return authorizedGet(PREFERENCES_PATH, accessToken);
  };

  const { isPending, isLoading, isFetched, isError, data, error } = useQuery({
    queryKey,
    queryFn,
    enabled: passportState.authenticated,
  });

  // ID-1736 - `data` wasn't correctly being invalidated post update causing the onboarding to repeatedly show.
  // This duplicate state was created to fix that.
  useEffect(() => {
    setPreferences(data);
  }, [data]);

  const mutationFn = async (preference: ProfilePreferences) => {
    const accessToken = await getAccessToken();
    if (!accessToken) return undefined;
    return authorizedPatch(PREFERENCES_PATH, preference, accessToken);
  };

  const mutation = useMutation({
    mutationFn,
    onSuccess: (data) => {
      setPreferences(data);
      queryClient.invalidateQueries({ queryKey });
    },
    retry: 1,
  });

  const update = useCallback(
    (preference: PartialProfilePrefences) =>
      mutation.mutate({ ...data, ...preference }),
    [data, mutation.mutate],
  );

  const reset = useCallback(() => {
    update({
      marketing_consent: "subscribed", // According to braze -> 'A user has neither unsubscribed nor explicitly opted-in to receive emails. This is the default subscription state when a user profile is created.'
      onboard: true, // Should a User be pushed through onboarding
      reveal_address: false, // Has a User chose to reveal their Wallet Address
    });
  }, [update]);

  useEffect(() => {
    if (isFetched && (error as AxiosError)?.response?.status === 404) {
      reset();
    }
  }, [isFetched, error, reset]);

  return (
    <PreferencesContext.Provider
      value={{
        isPending,
        isError,
        isLoading,
        isFetched,
        data: preferences,
        error,
        update,
        reset,
        errorStatus: (error as AxiosError)?.response?.status,
      }}
    >
      {children}
    </PreferencesContext.Provider>
  );
};

export { PreferencesProvider, usePreferences };
