import { z } from "zod";

import { type ChainAddress, EnvironmentNames } from "@/types";

const ethereumAddress = () =>
  z.custom<ChainAddress>(
    (val) => typeof val === "string" && val.startsWith("0x"),
  );

export const schema = z
  .object({
    ASSET_BASE_URL: z.string().url(),
    AUTH0_AUDIENCE: z.string(),
    AUTH0_CLIENT_ID: z.string(),
    AUTH0_LOGOUT_MODE: z.string(),
    AUTH0_LOGOUT_REDIRECT_URI: z.string().url(),
    AUTH0_REDIRECT_URI: z.string().url(),
    AUTH0_SCOPE: z.string(),
    BRAZE_API_KEY: z.string(),
    BRAZE_API_ENDPOINT: z.string(),
    CHAIN_NAME: z.string(),
    ENVIRONMENT: z.nativeEnum(EnvironmentNames),
    IMAGE_RESIZER_URL: z.string().url(),
    IMMUTABLE_BASE_URI: z.string().url(),
    IMX_CONTRACT_ADDRESS: ethereumAddress(),
    LAUNCH_DARKLY_CLIENT_ID: z.string(),
    LAYERSWAP_CLIENT_ID: z.string(),
    MOONPAY_API_KEY: z.string(),
    NEW_RELIC_APPLICATION_ID: z.string(),
    NEW_RELIC_LICENSE_KEY: z.string(),
    NODE_ENV: z.string(),
    SEGMENT_APP_NAME: z.string(),
    SEGMENT_KEY: z.string(),
    TOKEN_ICONS_URL: z.string().url(),
    WALLET_CONNECT_PROJECT_ID: z.string().optional(),
    ZK_EXPLORER_BASE_URL: z.string().url(),
    ZK_CHAIN_ID: z.string().transform((arg) => Number.parseInt(arg)),
    ZK_RPC_URL: z.string().url(),
    MODAL_WIDTH: z.string(),
  })
  .refine(
    (data) =>
      data.ENVIRONMENT === EnvironmentNames.DEV ||
      ((data.ENVIRONMENT === EnvironmentNames.PRODUCTION ||
        data.ENVIRONMENT === EnvironmentNames.SANDBOX) &&
        data.WALLET_CONNECT_PROJECT_ID !== undefined),
    {
      message:
        "WALLET_CONNECT_PROJECT_ID must be set in production or sandbox environments",
    },
  );

export const appConfigFn = () =>
  schema.parse({
    ASSET_BASE_URL: process.env.NEXT_PUBLIC_ASSET_BASE_URL,
    AUTH0_AUDIENCE: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
    AUTH0_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    AUTH0_LOGOUT_MODE: process.env.NEXT_PUBLIC_AUTH0_LOGOUT_MODE,
    AUTH0_LOGOUT_REDIRECT_URI:
      process.env.NEXT_PUBLIC_AUTH0_LOGOUT_REDIRECT_URI,
    AUTH0_REDIRECT_URI: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI,
    AUTH0_SCOPE: process.env.NEXT_PUBLIC_AUTH0_SCOPE,
    BRAZE_API_KEY: process.env.NEXT_PUBLIC_BRAZE_API_KEY,
    BRAZE_API_ENDPOINT: process.env.NEXT_PUBLIC_BRAZE_API_ENDPOINT,
    CHAIN_NAME: process.env.NEXT_PUBLIC_CHAIN_NAME,
    ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
    IMAGE_RESIZER_URL: process.env.NEXT_PUBLIC_IMAGE_RESIZER_URL,
    IMMUTABLE_BASE_URI: process.env.NEXT_PUBLIC_IMMUTABLE_BASE_URI,
    IMX_CONTRACT_ADDRESS: process.env.NEXT_PUBLIC_IMX_CONTRACT_ADDRESS,
    LAUNCH_DARKLY_CLIENT_ID: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID,
    LAYERSWAP_CLIENT_ID: process.env.NEXT_PUBLIC_LAYERSWAP_CLIENT_ID,
    MOONPAY_API_KEY: process.env.NEXT_PUBLIC_MOONPAY_API_KEY,
    MODAL_WIDTH: "430px",
    NEW_RELIC_APPLICATION_ID: process.env.NEXT_PUBLIC_NEW_RELIC_APPLICATION_ID,
    NEW_RELIC_LICENSE_KEY: process.env.NEXT_PUBLIC_NEW_RELIC_LICENSE_KEY,
    NODE_ENV: process.env.NODE_ENV,
    SEGMENT_APP_NAME: process.env.NEXT_PUBLIC_SEGMENT_APP_NAME,
    SEGMENT_KEY: process.env.NEXT_PUBLIC_SEGMENT_KEY,
    TOKEN_ICONS_URL: process.env.NEXT_PUBLIC_TOKEN_ICONS_URL,
    WALLET_CONNECT_PROJECT_ID:
      process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
    ZK_EXPLORER_BASE_URL: process.env.NEXT_PUBLIC_ZK_EXPLORER_BASE_URL,
    ZK_CHAIN_ID: process.env.NEXT_PUBLIC_ZK_CHAIN_ID,
    ZK_RPC_URL: process.env.NEXT_PUBLIC_ZK_RPC_URL,
  });
