import type { ReactNode } from "react";
import { http, WagmiProvider as WagmiConfig, createConfig } from "wagmi";
import { immutableZkEvm, immutableZkEvmTestnet, mainnet } from "wagmi/chains";
import { injected, walletConnect } from "wagmi/connectors";

import { appConfig } from "@/constants";
import { EnvironmentNames } from "@/types";

const passportUrl =
  appConfig.ENVIRONMENT === EnvironmentNames.PRODUCTION
    ? "https://passport.immutable.com"
    : `https://passport.${appConfig.ENVIRONMENT}.immutable.com`;

const config = createConfig({
  chains: [mainnet, immutableZkEvm, immutableZkEvmTestnet],
  transports: {
    [mainnet.id]: http(),
    [immutableZkEvm.id]: http(),
    [immutableZkEvmTestnet.id]: http(),
  },
  connectors: appConfig.WALLET_CONNECT_PROJECT_ID
    ? [
        injected(),
        walletConnect({
          projectId: appConfig.WALLET_CONNECT_PROJECT_ID,
          metadata: {
            name: "Immutable Passport Dashboard",
            description: "Immutable Passport Dashboard",
            url: passportUrl,
            icons: [],
          },
        }),
      ]
    : [injected()],
});

export function WagmiProvider({ children }: { children: ReactNode }) {
  return <WagmiConfig config={config}>{children}</WagmiConfig>;
}
