import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { BigNumber } from "bignumber.js";
import {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { appConfig } from "@/constants";
import { useSupportedCurrenciesQuery } from "@/hooks";
import { EnvironmentNames } from "@/types";
import { notifyError } from "@/utils/monitoring";

type FiatPrice = {
  usd: number;
};

type CryptoFiatPrices = {
  eth: FiatPrice;
  imx: FiatPrice;
  gods: FiatPrice;
  usdc: FiatPrice;
};

const coinGeckoIdMap: Record<string, string> = {
  eth: "ethereum",
  imx: "immutable-x",
  gods: "gods-unchained",
  gog: "guild-of-guardians",
  usdc: "usd-coin",
  usdt: "tether",
};

interface Context {
  fiatPrices?: CryptoFiatPrices | undefined;
  buildFiatEquivalentPrice: (
    tokenPrice: string,
    decimals: number,
    tokenSymbol: string,
    fiatPrices: CryptoFiatPrices | undefined,
    fiatCurrency?: string,
  ) =>
    | {
        currency: string;
        amount: string;
        prefixSymbol: string;
      }
    | undefined;
  setTokenSymbols: (tokenSymbols: string[]) => void;
}

export const FiatPricingContext = createContext<Context>({
  fiatPrices: undefined,
  buildFiatEquivalentPrice: () => undefined,
  setTokenSymbols: () => undefined,
});

export const useFiatPricingContext = (): Context =>
  useContext(FiatPricingContext);

async function getCheckoutApiPrices(
  tokenSymbols: string[],
  fiatSymbol = "usd",
) {
  const coinGeckoIds = tokenSymbols?.map(
    (symbol) => coinGeckoIdMap[symbol.toLowerCase()] || symbol.toLowerCase(),
  );
  const idsParam = coinGeckoIds?.join(",");
  const currenciesParam = fiatSymbol.toLowerCase();

  const baseUrl =
    appConfig.ENVIRONMENT === EnvironmentNames.PRODUCTION
      ? "https://checkout-api.immutable.com"
      : `https://checkout-api.${appConfig.ENVIRONMENT}.immutable.com`;
  const path = `/v1/fiat/conversion?ids=${idsParam}&currencies=${currenciesParam}`;

  const { data } = await axios.get(baseUrl + path);
  return data;
}

// TODO @pano @ji: This is actually only a Fiat pricing provider for tokens that Moonpay supports :(
export function FiatPricingProvider({
  children,
  fiatSymbol = "usd",
}: PropsWithChildren<{ fiatSymbol?: string }>) {
  const [tokenSymbols, setTokenSymbols] = useState(["eth"]);
  const { data: supportedCurrencies, isLoading } =
    useSupportedCurrenciesQuery();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (isLoading) return;

    if (supportedCurrencies) {
      setTokenSymbols(supportedCurrencies);
    }
  }, [isLoading, setTokenSymbols, supportedCurrencies]);

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const { data } = useQuery<any, any, CryptoFiatPrices, any>({
    queryKey: ["checkoutApiPrices", tokenSymbols, fiatSymbol],
    refetchInterval: 1000 * 60 * 5, // refetch every 5 minutes
    queryFn: () => getCheckoutApiPrices(tokenSymbols, fiatSymbol),
    select: (data) => ({
      eth: data[coinGeckoIdMap.eth],
      imx: data[coinGeckoIdMap.imx],
      gods: data[coinGeckoIdMap.gods],
      usdc: data[coinGeckoIdMap.usdc],
      gog: data[coinGeckoIdMap.gog],
      usdt: data[coinGeckoIdMap.usdt],
    }),
  });

  function buildFiatEquivalentPrice(
    tokenPrice: string,
    tokenDecimals: number,
    tokenSymbol: string,
    fiatPrices: CryptoFiatPrices | undefined,
    fiatCurrency = "usd",
  ) {
    if (!fiatPrices) {
      return;
    }

    if (!tokenSymbol) {
      const tokenSymbolError = new Error("Token Symbol does not exist");
      notifyError(tokenSymbolError, "appError");
      return;
    }

    const symb = tokenSymbol.toLowerCase();

    if (!(symb in fiatPrices)) {
      return;
    }

    const fiatEquivalentFormattedPrice = new BigNumber(tokenPrice)
      .multipliedBy(
        (fiatPrices[symb as keyof CryptoFiatPrices] as FiatPrice)?.usd,
      )
      .div(10 ** tokenDecimals, 10)
      .toFormat(2);

    return {
      currency: `${
        fiatEquivalentFormattedPrice === "0.00" ? "" : "~ "
      }${fiatCurrency.toUpperCase()}`,
      amount: fiatEquivalentFormattedPrice,
      prefixSymbol: fiatCurrency === "usd" ? "$" : "",
    };
  }

  return (
    <FiatPricingContext.Provider
      value={{ fiatPrices: data, buildFiatEquivalentPrice, setTokenSymbols }}
    >
      {children}
    </FiatPricingContext.Provider>
  );
}
