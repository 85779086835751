import axios, { AxiosError } from "axios";

import { appConfig } from "@/constants";

const baseUrl = appConfig.IMMUTABLE_BASE_URI;

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function retryQuery(failureCount: number, error: any): boolean {
  // Don't retry client side errors
  if (
    error instanceof AxiosError &&
    error.response?.status &&
    error.response?.status.toString().startsWith("4")
  ) {
    return false;
  }

  return failureCount <= 3;
}

export async function authorizedGet(path: string, accessToken: string) {
  const resp = await axios.get(baseUrl + path, {
    headers: {
      "Content-Type": "application/json", // eslint-disable-line
      Authorization: `Bearer ${accessToken}`,
      "Access-Control-Allow-Origin": "*", // eslint-disable-line
    },
  });

  return resp.data;
}

export async function authorizedDelete(path: string, accessToken: string) {
  const resp = await axios.delete(baseUrl + path, {
    headers: {
      "Content-Type": "application/json", // eslint-disable-line
      Authorization: `Bearer ${accessToken}`,
      "Access-Control-Allow-Origin": "*", // eslint-disable-line
    },
  });

  return resp.data;
}

export async function authorizedPost(
  path: string,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  data: any,
  accessToken: string,
) {
  const resp = await axios.post(baseUrl + path, data, {
    headers: {
      "Content-Type": "application/json", // eslint-disable-line
      Authorization: `Bearer ${accessToken}`,
      "Access-Control-Allow-Origin": "*", // eslint-disable-line
    },
  });

  return resp.data;
}

export async function authorizedPatch(
  path: string,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  data: any,
  accessToken: string,
) {
  const resp = await axios.patch(baseUrl + path, data, {
    headers: {
      "Content-Type": "application/json", // eslint-disable-line
      Authorization: `Bearer ${accessToken}`,
      "Access-Control-Allow-Origin": "*", // eslint-disable-line
    },
  });

  return resp.data;
}
